.text-slider {
  /* color used to softly clip top and bottom of the .words container */
  --bg-color: #00000000;
  background-color: var(--bg-color);
  padding: 1rem 2rem;
  border-radius: 1.25rem;
  font-size: 75px;
}
.loader {
  color: rgb(124, 124, 124);
  font-family: "Poppins", sans-serif;
  /* font-weight: 500; */
  font-size: 75px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 100px;
  /* padding: 10px 10px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
}

.words {
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
}
.words::after {
  content: "";
  position: absolute;
  inset: 0;
  /* background: linear-gradient(var(--bg-color) 0%, transparent 30%, transparent 70%, var(--bg-color) 100%); */
  z-index: 20;
}

.word {
  display: block;
  height: 100%;
  /* padding-left: 6px; */
  color: #956afa;
  animation: spin_4991 4s infinite;
}

@keyframes spin_4991 {
  10% {
    -webkit-transform: translateY(-102%);
    transform: translateY(-102%);
  }

  25% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  35% {
    -webkit-transform: translateY(-202%);
    transform: translateY(-202%);
  }

  50% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }

  60% {
    -webkit-transform: translateY(-302%);
    transform: translateY(-302%);
  }

  75% {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  85% {
    -webkit-transform: translateY(-402%);
    transform: translateY(-402%);
  }

  100% {
    -webkit-transform: translateY(-400%);
    transform: translateY(-400%);
  }
}

@media only screen and (max-width: 1280px) {
  .text-slider {
    font-size: 48px;
  }
  .loader {
    font-size: 48px;
  }
}

@media only screen and (max-width: 1024px) {
  .text-slider {
    font-size: 45px;
  }
  .loader {
    font-size: 45px;
  }
}

@media only screen and (max-width: 992px) {
  .text-slider {
    font-size: 35px;
  }
  .loader {
    font-size: 35px;
  }
}
