@font-face {
  font-family: "cleo";
  src: url("./../../public/fonts/cleo.ttf") format("truetype");
}

body {
  scroll-behavior: smooth;
}

.hero,
.hero2 {
  height: 100dvh;
  /* background-color: black; */
}
.hero2 {
  overflow-x: clip;
}

.bg-black {
  background-color: black;
}

.hero-img {
  width: 100%;
  max-height: 500px;
}
.hero-logo {
  width: auto;
  height: 100px;
}

.h-font {
  font-family: "cleo";
  color: #dcc5ff;
}

.clip-box {
  width: 100%;
  height: 100dvh;
  position: relative;
  background-color: #dcc5ff;
  border-radius: 30px 0 0 30px;
}
.patterns {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center; */
  z-index: 1;
}

.patterns img {
  width: auto;
  height: 300px;
}

.patterns img:nth-child(1) {
  animation: 5s wiggleleft ease-in-out infinite;
}
.patterns img:nth-child(2) {
  animation: 5s wiggleright ease-in-out infinite;
}

@keyframes wiggleleft {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes wiggleright {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0);
  }
}

.motion-drinks {
  position: relative;
  animation: 1s wobble ease-in-out forwards;
  width: auto;
}

.drinks-wrapper {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.motion-drinks::before {
  content: "";
  height: 500px;
  width: 350px;
  background-color: rgb(0, 0, 0);
  border-radius: 30px;
  position: absolute;
  bottom: -250px;
  left: 50%;
  transform: translateX(-50%);
}

.motion-drinks img {
  height: 600px;
  width: auto;
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes wobble {
  0% {
    opacity: 0;
    margin-bottom: -200px;
  }

  100% {
    opacity: 1;
    margin-bottom: 0px;
  }
}

.motion-drinks .img-1 {
  /* animation: 4s chameleon ease-in-out infinite; */
}
.motion-drinks .img-2 {
  /* animation: 4s chameleon 2s ease-in-out infinite; */
}
.motion-drinks .img-3 {
  /* animation: 4s chameleon 6s ease-in-out infinite; */
}

@keyframes chameleon {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.spinner-load {
  height: 100dvh;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: white;
  animation: 1s hideout 2s ease-out forwards;
  cursor: none;
}
@keyframes hideout {
  0% {
    height: 100dvh;
    display: flex;
    opacity: 1;
  }
  90% {
    height: 0dvh;
    display: flex;
    opacity: 1;
  }
  100% {
    height: 0dvh;
    opacity: 0;
    display: none;
  }
}

.flower-pattern {
  animation: 20s spinner linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-load .flower-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: 2s logo-blink ease-out forwards;
}

@keyframes logo-blink {
  0% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0);
}

body::-webkit-scrollbar-thumb {
  background-color: #dcc5ff;
  border-radius: 10px;
}

.thinScroll::-webkit-scrollbar {
  width: 5px;
}
.thinScroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0);
}

.thinScroll::-webkit-scrollbar-thumb {
  background-color: #dcc5ff;
  border-radius: 10px;
}

.bg-pattern {
  position: absolute;
  z-index: 1;

  height: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(25deg);
  opacity: 0.3;
  animation: 20s moveslow ease-in infinite;
}

.drink-img {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
}

@keyframes moveslow {
  0%,
  100% {
    transform: translate(-50%, -50%) rotate(25deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

/* ItemVid */
.itemVid video {
  object-fit: cover;
}

.itemVid2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.itemPos {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 30%;
  transform: translate(-50%, -40%);
}

.clipVid {
  height: 100%;
  width: 100%;
  font-size: 140px !important;
  font-family: Biko, sans-serif;
  font-weight: 700;
  /* line-height: 100dvh; */
  text-transform: uppercase;
  text-align: center;
  background: white;
  mix-blend-mode: screen;
  border-radius: 20px;
}

/* @media only screen and (max-width: 768px) {
  .clipVid {
    font-size: 60px !important;
  }
  .carousel .captionsVid h4,
  .carousel .captionsVid button {
    font-size: 1.5rem !important;
  }
  .carousel .captionsVid button {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 512px) {
  .clipVid {
    font-size: 60px !important;
  }
  .itemPos {
    bottom: 5%;
  }
  .carousel .captionsVid h4,
  .carousel .captionsVid button {
    font-size: 1.2rem !important;
  }
  .carousel .captionsVid button {
    font-size: 1rem !important;
  }
} */

/* Feature Section */
.features-section {
  width: 100%;
  padding: 10px;
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, transparent, #e1d8d2 0.1%, #e1d8d2 80%, transparent);
  mask: linear-gradient(90deg, transparent, #e1d8d2 0.1%, #e1d8d2 80%, transparent);
  display: flex;
  flex-wrap: wrap;
  padding: 50px 50px;
}

/* Feature Card */

.featurecard {
  border-radius: 2.5rem;
  cursor: pointer;
  overflow: hidden;
  padding: 20px;
  width: 600px;
  position: relative;
  height: 600px;

  background-size: 160%;
  /* outline: 5px solid #351518; */
  transition: all 0.5s ease-in-out;
}
.featurecard p {
  overflow-y: auto;
  font-family: "PT Serif";
  height: 300px;
  line-height: 39.75px;
}

.featurecard .bottom-star {
  position: absolute;
  bottom: 2%;
  right: 2%;
  animation: jump 2s ease-in-out infinite;
}
.featurecard:nth-child(odd) .bottom-star {
  animation-delay: 0.5;
}

@keyframes jump {
  0% {
    bottom: 2%;
  }

  50% {
    bottom: 5%;
  }
  10% {
    bottom: 2%;
  }
}

.featurecard:hover {
  background-size: 180%;
  /* outline: 5px dashed #351518; */
  scale: 1.02;
}

.features-section-inner {
  width: max-content;
  display: flex;
  flex-wrap: nowrap;
  gap: 2rem;
  animation: hscroll 40s linear infinite;
  animation-play-state: paused;
}

.features-section.animated > .features-section-inner {
  width: max-content;
  display: flex;
  flex-wrap: nowrap;
  gap: 2rem;
  animation: hscroll 40s linear infinite;
  animation-play-state: running;
}

@keyframes hscroll {
  to {
    transform: translate(calc(-50% - 1rem));
  }
}

.clipVid2 {
  height: 100%;
  width: 100%;
  background: white;
  mix-blend-mode: hard-light;
}

.features-section2 {
  width: 100%;
  height: 448%; /* Adjust height as needed */
  padding: 10px;

  overflow: hidden;
  -webkit-mask: linear-gradient(transparent, #e1d8d2 0.1%, #e1d8d2 80%, transparent);
  mask: linear-gradient(transparent, #e1d8d2 0.1%, #e1d8d2 80%, transparent);
  display: flex;
  flex-direction: column; /* Stacks items vertically */
  padding: 50px 50px;
}

.features-section2-inner {
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  animation: vscroll2 20s linear infinite;
  animation-play-state: paused;
}

.features-section2.animated > .features-section2-inner {
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  animation: vscroll2 20s linear infinite;
  animation-play-state: running;
}

@keyframes vscroll2 {
  to {
    transform: translateY(calc(-50% - 1rem)); /* Move items up */
  }
}
.flippersWrapper {
  max-height: 693px;
}

.flippersWrapper2 {
  max-height: 550px;
}

.flippersWrapper .h-lg-100,
.flippersWrapper2 .h-lg-100 {
  height: 100%;
}

/* responsive code start */
@media only screen and (max-width: 1400px) {
  .flippersWrapper .h-lg-100,
  .flippersWrapper2 .h-lg-100 {
    max-height: 70%;
  }
}

@media only screen and (max-width: 1350px) {
  .flippersWrapper .h-lg-100,
  .flippersWrapper2 .h-lg-100 {
    max-height: 65%;
  }
}

@media only screen and (max-width: 1280px) {
  .flippersWrapper .h-lg-100,
  .flippersWrapper2 .h-lg-100 {
    max-height: 60%;
  }
}

@media only screen and (max-width: 1100px) {
  .flippersWrapper .h-lg-100,
  .flippersWrapper2 .h-lg-100 {
    max-height: 55%;
  }
}

@media only screen and (max-width: 993px) {
  .clip-box {
    width: 100%;
    height: 50dvh;
    position: relative;
    background-color: #dcc5ff;
    border-radius: 30px;
  }
  .patterns img {
    width: auto;
    height: 200px;
  }
  .motion-drinks::before {
    content: "";
    height: 300px;
    width: 250px;
    background-color: rgb(0, 0, 0);
    border-radius: 30px;
    position: absolute;
    bottom: -150px;
    left: 50%;
    transform: translateX(-50%);
  }

  .motion-drinks img {
    height: 350px;
    width: auto;
    position: absolute;
    z-index: 5;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .hero {
    padding-top: 110px;
    height: 100%;
  }

  .hero p {
    font-size: 0.8em !important;
    margin-bottom: 0 !important;
  }

  .no-round-sm {
    border-radius: 0 !important;
  }

  .hero2 {
    height: 100%;
    margin-top: 50px;
  }
  .hero2 p {
    font-size: 0.8em !important;
    margin-top: 10px;
  }
  .drink-img {
    position: static;
    transform: none;
    height: 250px;
  }
  .bg-pattern {
    height: 300px;
  }

  .clipVid img {
    height: 200px !important;
  }

  .flippersWrapper,
  .flippersWrapper2 {
    max-height: 100%;
  }

  .flippersWrapper .h-lg-100,
  .flippersWrapper2 .h-lg-100 {
    height: auto !important;
  }

  .featurecard {
    padding: 10px;
    width: 300px;
    height: 300px;
    background-size: 160%;
  }
  .featurecard p {
    height: 150px;
    font-size: 1.5em !important;
    line-height: 25px;
  }
  .featurecard .bottom-star img {
    scale: 0.8;
  }
  .wavesTrim {
    height: 55dvh !important;
  }
  .alt-drink-front {
    height: 60% !important;
  }
  .alt-drink-bg {
    height: 90% !important;
  }
  .alt-drink-wrapper {
    height: 60dvh !important;
  }
}

@media only screen and (max-width: 420px) {
  .motion-drinks img {
    height: 280px;
  }
  .motion-drinks::before {
    content: "";
    height: 220px;
    bottom: -100px;
    width: 200px;
  }
  .patterns img {
    width: auto;
    height: 100px;
  }
  .clipVid img {
    height: 100px !important;
  }
}
